"use client";

import {useState} from "react";
import {z} from "zod";

import Icon from "@/components/atoms/Icon";
import Form from "@/components/forms/Form";
import {FiltersSidebar} from "@/components/organisms/FiltersSidebar";
import {BlogCategoryOverviewResult, BlogOverviewGraphqlResult} from "@/graphql/sdk/__generated__";
import {useGetParams, useRefreshSearchParams} from "@/helpers/searchBlog";
import {useTranslation} from "@/i18n/client";
import { Pagination } from 'antd';
import {
  AllowedBlogOverviewSearchParams,
  AllowedLanguages,
} from "@/types/generic";
import Checkbox from "@/components/forms/controls/Checkbox";
import {Loader} from "@/components/atoms/Loader";
import {Sausage} from "@/components/atoms/Sausage";

export const dummyFilters = [
  { value: "Over Vivaldis", id: "423" },
  { value: "HR news", id: "424" },
  { value: "Werken bij", id: "425" },
];

const schema = z.object({
  [AllowedBlogOverviewSearchParams.Language]: z.string().optional(),
  [AllowedBlogOverviewSearchParams.Page]: z.string().optional(),
  [AllowedBlogOverviewSearchParams.TargetAudience]: z.string().optional(),
  [AllowedBlogOverviewSearchParams.BlogCategories]: z.array(z.string()).optional(),
});
type Schema = z.infer<typeof schema>;

const filterOrder = [
  AllowedBlogOverviewSearchParams.BlogCategories,
];

interface SearchBlogFilterFormProps {
  //allBlogs: Blog[];
  children: React.ReactNode;
  results: BlogOverviewGraphqlResult[];
  filters: BlogCategoryOverviewResult[];
  pageInfo: { page: number, pageSize: number, total: number};
  i18n: {
    active_filters: string;
    clear_filters: string;
    filters_label: string;
    categories_label: string;
    close_sidebar: string;
    clear_recent_searches: string;
    new: string;
    no_results: string;
    blogs_found: string;
    blog_found: string;
    recent_searches: string;
    search_query_label: string;
    search_query_placeholder: string;
    show: string;
    title: string;
  };
  locale: AllowedLanguages;
  resultCount?: number;
}

export const SearchBlogFilterForm = ({
  resultCount,
  results,
  filters,
  children,
  i18n,
  locale,
  pageInfo
}: SearchBlogFilterFormProps) => {
  const params = useGetParams();
  /*const [viewMode, setViewMode] = useState<"map" | "list">("list");*/
  const [clickedFilter, setClickedFilter] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const {t} = useTranslation(locale);
  const filterList = filters.blogCategoryOverview.results;
  const defaultValues: Schema = {
    [AllowedBlogOverviewSearchParams.Page]:
    params.get(AllowedBlogOverviewSearchParams.Page) || undefined,
    [AllowedBlogOverviewSearchParams.BlogCategories]:
    params.get(AllowedBlogOverviewSearchParams.BlogCategories)?.split("|").map(Number) ?? [],
  };

  const {updatePage, isPending, updateFilter, updateQuery, form, selectedFilters} =
    useRefreshSearchParams(
      schema,
      [AllowedBlogOverviewSearchParams.BlogCategories],
      defaultValues,
    );

  const handlePageChange = (page: number) => {
    updatePage(page - 1);
    const blogList = document.querySelector('h1.title-line');
    if (blogList) {
      if (blogList) {
        blogList.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offsetTop: -500 });
      }
    }
  };

  const handleSubmit = (query: Schema) => {
    updateFilter(AllowedBlogOverviewSearchParams.BlogCategories, '');
    updateFilter(AllowedBlogOverviewSearchParams.Page, '');
  };

  return (
    <>
      <div className="relative z-1 pb-4 pt-8 lg:pb-0 lg:pt-12 xl:pt-20">
        <div className="container">
          <div
            className="lg:mb-4 flex flex-col flex-wrap gap-6 lg:gap-[220px] md:flex-row md:items-center md:justify-between lg:mb-12">
            <h1 className="!mb-0 title-line">{i18n.title}</h1>
            <Sausage
                className="ml-auto bg-white xl:hidden"
                onClick={() => {
                  setClickedFilter(null);
                  setFiltersOpen(true);
                }}
            >
              {i18n.filters_label}
              <Icon name={"sliders"} className="h-4 w-4"/>
            </Sausage>
          </div>
        </div>
      </div>
      <Form form={form} onSubmit={handleSubmit} className="relative z-1">
        <div className="container pb-10 pt-4 lg:pb-24 lg:pt-12">
          <div className="grid grid-cols-12 xl:gap-8">
            <div className="col-span-12 xl:col-span-9">
              <div>
                {isPending ? (
                  <Loader locale={locale} className="my-10"/>
                ) : (
                  <>
                    <div className="mb-4 flex items-center justify-between">
                      <h2 className="h5 text-black-300">
                        {pageInfo.total} {pageInfo.total !== 1 ? i18n.blogs_found : i18n.blog_found}
                      </h2>
                    </div>
                    {children}
                    <Pagination
                        total={pageInfo.total}
                        current={pageInfo.page + 1}
                        pageSize={pageInfo.pageSize}
                        onChange={(page, pageSize) => handlePageChange(page)}
                        className="number-pagination !mt-6 lg:mt-12 xl:!mt-14 w-full inline-flex justify-center mx-auto"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="hidden xl:block xl:col-span-3">
              <div className="border-b pb-8 last:border-b-0 last:pb-0 lg:rounded-lg lg:border lg:p-8 lg:last:border-b lg:last:pb-8">
                <div className="flex justify-between gap-4 mb-6 lg:border-b lg:pb-6">
                  <h5 className="font-medium leading-none !mb-0">{i18n.categories_label}</h5>
                </div>
                <div className={"flex flex-col gap-2"}>
                  {filterList.map((filter, index) => (
                    <Checkbox
                        asArray={true}
                        label={filter?.name ?? ""}
                        name={AllowedBlogOverviewSearchParams.BlogCategories}
                        onCheckedChange={updateFilter}
                        selectedFilters={selectedFilters}
                        value={filter?.tid ?? ""}
                        key={index}
                    ></Checkbox>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <FiltersSidebar
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            clickedFilter={clickedFilter ?? undefined}
            customCountLabel={`${i18n.show} (${pageInfo.total ?? undefined})`}
            i18n={{
              clear_filters: i18n.clear_filters,
              close_sidebar: i18n.close_sidebar,
              show: i18n.show,
              entity_title: i18n.title,
            }}
            resultCount={pageInfo.total ?? undefined}
        >
          <div className={"flex flex-col gap-2"}>
            <div className="flex justify-between gap-4 mb-6 lg:border-b lg:pb-6">
              <h5 className="font-medium leading-none !mb-0">{i18n.categories_label}</h5>
            </div>
            {filterList.map((filter, index) => (
                <Checkbox
                    asArray={true}
                    label={filter?.name ?? ""}
                    name={AllowedBlogOverviewSearchParams.BlogCategories}
                    onCheckedChange={updateFilter}
                    selectedFilters={selectedFilters}
                    value={filter?.tid ?? ""}
                    key={index}
                ></Checkbox>
            ))}
          </div>
        </FiltersSidebar>
      </Form>
    </>
  );
};
